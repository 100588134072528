<template>
  <div class="trending min_main">
    <div class="container">
        <div class="page_width">
           
            <div class="trending_title flex">
                <div class="trending_title_icon"></div>
                <div class="trending_title_name">{{ title }}</div>
            </div>
            <div class="page_empty flex_col_content" v-if="brands.length==0">
                <div class="page_title" style="color:#000">
                    <div class="title_name">{{ brand_title }}</div>
                </div>
                <div class="empty_icon">
                    <img src="https://www.g2g.com/img/error-no-result.c689f3a4.svg" alt="">
                </div>
                <div class="empty_msg">More games coming up</div>
            </div>
            <div class="trending_container" v-else>
                <div class="trending_title text_left">{{ brand_title }}</div>
                <div class="trending_content grid grid_md_gap grid_xs_gap" >
                    <div class="page_thumb grid_md_3 grid_xs_4 grid_sm_6"  v-for="(item,index) in brands" :key="index" @click="toPage('category',item.seo_term,item.brand_name)">
                        <div class="page_container cate_container slide_cate" v-if="item.brand_img_url">       
                            <div class="cate_tab pointer" >
                                <!-- <img :src="https://assets.g2g.com/img/offer/kr_90015a0f-3983-4953-8368-96ac181d9e92_lgc_game_33618.webp" alt=""> -->
                                <el-image :src="item.brand_img_url" >
                                    <div slot="error" class="image-slot flex_content">
                                    <i class="err_img">
                                        <img src="../../assets/image/err.jpg" alt="">
                                    </i>
                                    </div>
                                    <div slot="placeholder" class="image-slot flex_content">
                                    Loading<span class="dot">...</span>
                                    </div>
                                </el-image>
                                <!-- <div class="cate_tab_count">89 offers</div> -->
                                <div class="cate_tab_title">{{ item.brand_name }}</div>
                            </div>   
                          
                        </div>
                        <div class="page_container cate_container brand_container" v-if="!item.brand_img_url">       
                         
                            <div class="cate_tab pointer flex_content cate_brand">
                                <div class="brand_name">{{ item.brand_name }}</div>
                                <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAzMCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMjRIMjQuNUMyNy4yNjE0IDI0IDI5LjUgMjEuNzYxNCAyOS41IDE5VjBMMCAyNFoiIGZpbGw9IiNEQkNEQTUiLz4KPC9zdmc+Cg==" alt="" class="">
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {
      menuList:[
        {name:'games',brand_id:'d3cf5b03-b80b-4f7e-bf97-ed359903de0b',title:'All brands for Games'},
        {name:'game coins',brand_id:'3c2a9034-2569-4484-92ad-c00e384e7085',title:'All brands for Game coins'},
        {name:'items',brand_id:'c508fb9f-a58e-4819-b815-c4684d8a2e70',title:'All brands for Items'},
        {name:'account',brand_id:'5830014a-b974-45c6-9672-b51e83112fb7',title:'All brands for Accounts'},
        {name:'skins',brand_id:'6fd06182-b5a4-4d13-866a-46d39631a26d',title:'All brands for Skins'},
      ],
      title:'',
      brand_title:'',
    };
  },
  created(){
   
    this.title = this.$route.query.title
    this.getBrand()
  },
  methods:{
    toPage(e,seo_term,title){
        if(e=='category'){
            this.$router.push('/'+e+'?seo_term='+seo_term+'&title='+title)
            return
        }
        this.$router.push('/'+e)
    },
    getBrand(){
        let title = this.title
        let list = this.menuList
        let brand_id =''
         list.map(v=>{
            if(v.name == title){
                brand_id = v.brand_id
                this.brand_title = v.title
                return 
            }
        })
     
        this.$store.dispatch('getBrands',{brand_id})
    }
  },
  computed:{
    brands(){ return this.$store.state.brands }
  }
};
</script>
<style lang="scss" scoped>
.trending{
    .trending_title{
        margin:24px 0;
        font-weight: 600;
        width:100%;
        color:#303030;
        font-size:24px;
        .trending_title_icon{
            width:32px;
            height:32px;
            background-size: cover;
            background-image: url('https://assets.g2g.com/img/offer/cat_color_e09d4c61-a3e9-41cc-a2ef-b17ef0e19959.webp');
        }
        .trending_title_name{
            text-transform: capitalize;
            text-align: left;
            line-height: 1.3;
            margin-left:6px;
        }
    }
    .page_empty{
        .empty_icon{
            width:150px;
            height:150px;

        }
        .empty_msg{
            font-size:16px;
            color:#303030;
            margin-top:24px;
        }
    }
    .trending_container{

    }
    .brand_container{
        height: 120px;
        position: relative;
        transition: all 0.2s ease-out;
        border-radius: 8px;
        overflow: hidden;
        .cate_brand{
            position: relative;
            height: 100%;
            background-color: #f6edd4;
            clip-path: polygon(28px 0%, 100% 0, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 100%, 0% 24px);
            .brand_name{
                padding:16px;
                font-size:14px;
            }
            img{
                position: absolute;
                width:30px;
                height: 24px;
                left:0;
                top:0;
            }
            
        }
        &:hover {
                filter: drop-shadow(0 5px 7px rgba(0, 0, 0, 0.18));
                top: -4px;
                // box-shadow: 0 4px 6px rgba(0, 0, 0, .6), 0 0 6px rgba(0, 0, 0, .4);
            }
   
    }
    
}
</style>
